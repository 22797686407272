import { LoginConstant } from "../constants/auth.constant";
import baseUrlConfig from '../../env/baseUrl.config';
import axios from 'axios';


export const authActions = {
  login,
  logout,
  clearMsg,
};

function login(email: any, password: any, rememberMe: any) {
  return async (dispatch: (arg0: { type: string; user?: any; error?: any }) => void) => {
    dispatch(request({ email, password, rememberMe }));
    try {
      axios.post(baseUrlConfig.baseURL + 'jurror/login', { email: email, password: password }).then(res => {
        const { token } = res.data;
        if (token) {
          let loginData = res.data.data;
          loginData['token'] = token;
          dispatch(success(loginData));
        }
        else {
          console.log("err", res.data)
          dispatch(failure(res.data.msg));
        }
      }).catch(Err => {
        dispatch(failure("user doesnot exist"));
      })
    } catch (error) {
      dispatch(failure("Something went wrong"));
    }

    function request(user: { email: any; password: any; rememberMe: any }) {
      return { type: LoginConstant.REQUEST_LOGIN, user };
    }

    function success(user: any) {
      return { type: LoginConstant.SUCCESS_LOGIN, user };
    }

    function failure(error: any) {
      return { type: LoginConstant.FAILURE_LOGIN, error };
    }
  };
}

function logout() {
  return async (dispatch: (arg0: { type: string; user?: any; error?: any }) => void) => {
    
    dispatch({ type: LoginConstant.LOG_OUT });
    // await firebaseApp.auth().signOut();
  };
}

function clearMsg() {
  return (dispatch: (arg0: { type: string; user?: any; error?: any }) => void) => {
    dispatch({ type: LoginConstant.CLEARM_MSG });
  };
}
