import React from "react";
import async from "../components/Async";
import { Users,File } from "react-feather";

const Admin = async(() => import("../pages/users/admin"));
const Nomination = async(() => import("../pages/Nomination/nomination"));
const NominationID = async(() => import("../pages/Nomination/idNomination"));
const ViewNom = async(() => import("../pages/View/index"));
const AuthGuard = async(() => import("../components/AuthGuard"));
// Auth components
const SignIn = async(() => import("../pages/auth/SignIn"));
// const SignUp = async(() => import("../pages/auth/SignUp"));
const ResetPassword = async(() => import("../pages/auth/ResetPassword"));
const ForgotPassword = async(() => import("../pages/auth/ForgotPassword"));
const Page404 = async(() => import("../pages/auth/Page404"));
const Page500 = async(() => import("../pages/auth/Page500"));


const users = {
  id: "Jurrors",
  path: "/jurror",
  icon: <Users />,
  containsHome: true,
  children: null,
  component: Admin,
};

const nomination = {
  id: "All Nomination",
  path: "/nomination",
  icon: <File />,
  containsHome: true,
  children: null,
  component: Nomination,
};

const IdNomination = {
  id: "Nominations",
  path: "/assignments",
  icon: <File />,
  containsHome: true,
  children: null,
  component: NominationID,
};

const View = {
  id: "View",
  path: "/view",
  containsHome: true,
  children: null,
  component: ViewNom,
};



const authRoutes = {
  id: "Auth",
  path: "/",
  icon: <Users />,
  children: [
    {
      path: "/",
      name: "Sign In",
      component: SignIn,
    },
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: SignIn,
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: ResetPassword,
    },
    {
      path: "/auth/forgot-password",
      name: "Forgot Password",
      component: ForgotPassword,
    },

    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404,
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500,
    },
  ],
  component: null,
};

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [users,nomination,IdNomination,View];

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];

// Routes visible in the sidebar
export const sidebarRoutes = [users,nomination,IdNomination];
